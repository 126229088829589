<template>

    <div class="container-lg mb-3">
        <div class="header text-center text-md-start">
            <h3>
                <i class="bi bi-truck"></i>
                {{ $t('titles.deliveryList') }}
            </h3>
            <p class="week-date">
                {{ $t('dateTime.Week') }} {{ useDateTimeUtils.getWeekNumber(useDateTimeUtils.getMonday(state.date)) }}
                <span class="date-long">
                    ({{ useDateTimeUtils.showDate(useDateTimeUtils.getMonday(state.date)) }} {{ $t('dateTime.to') }} {{ useDateTimeUtils.showDate(useDateTimeUtils.getSunday(state.date)) }})
                </span>
            <!--
                <span class="mx-3">
                    {{ formatedDate(state.date) }}
                </span>
            -->
            </p>

        </div>


        <div class="row align-items-center date-controls">

            <div class="col-sm-12 col-md-4 mt-3 mt-md-0">
                <button
                    class="btn btn-primary me-2"
                    @click.prevent="printLabels(state.date)"
                >
                    {{ $t('form.actions.printLabels') }}
                </button>
            </div>



<!--
            <div class="col-sm-12 col-md-4 offset-md-4">
-->
            <div class="col-sm-12 col-md-4 mt-3 mt-md-0">
                <Datepicker
                    v-model="state.date"
                    :previewFormat="format"
                    @update:modelValue="updateDate"
                    :enableTimePicker="false"
                    locale="fr"
                    :format="format"
                    name="date-picker"

                ></Datepicker>
            </div>

            <div class="col-sm-12 col-md-4 text-end mt-3 mt-md-0">
                <button
                    class="btn btn-primary me-2"
                    @click.prevent="setToday()"
                >
                    {{ $t('dateTime.Today') }}
                </button>

                <button
                    class="btn btn-secondary prev-next me-1"
                    @click.prevent="previousDay()"
                >
                    <i class="bi bi-chevron-left"></i>
                </button>
                <button
                    class="btn btn-secondary prev-next"
                    @click.prevent="nextDay()"
                >
                    <i class="bi bi-chevron-right"></i>
                </button>
            </div>
        </div>





        <!-- ******************************************************** -->
        <!-- Display Meals list                                       -->
        <!-- ******************************************************** -->
        <div class="row mb-5">

            <div class="card text-center mt-4">
                <div class="card-body date-header">
                    {{ formatedDate(state.date) }}
                </div>
            </div>

            <div
                class="col-6 col-md-3"
                v-for="meal in useMeals.state.form.resources"
                v-bind:key="meal"
            >

                <div
                    class="card mt-3"
                    v-bind:class=" meal.group_id === null ? 'text-dark border-2 border-warning' : 'text-dark border-2 border-info'"
                >

                    <div
                        class="card-body"
                        @click.prevent="showResource(meal)"
                    >
                        <p class="mb-1">{{ meal.lastname }} {{ meal.firstname }}</p>
                        <p class="mb-1">{{ meal.postcode }} {{ meal.city }}</p>
                        <p class="mb-1">{{ $t('models.meal.portions') }} : {{ meal.portion_qty }}</p>
                        <p class="mb-1">{{ $t('models.meal.half_portions') }} : {{ meal.half_portion_qty }}</p>
                        <p
                            class="mb-1 bg-danger text-white"
                            v-if="meal.comment"
                        >
                            {{ $t('models.meal.comment') }} : {{ meal.comment }}
                        </p>

                    </div>
                </div>
            </div>

        </div>

        <MyModal
            v-bind:idProps="state.modal.show"
            @click.prevent="state.modal.componentShow.toggle()"
        >
            <!--    Content for header slot     -->
            <template v-slot:header>
                {{ state.modal.header }}
            </template>

            <template v-slot:body>

                <table class="table">
                    <thead>
                        <th scope="col">{{ $t('objects.index') }}</th>
                        <th scope="col">{{ $t('objects.item') }}</th>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(item, index) in state.models.mealToShow"
                            v-bind:key="item"
                        >
                            <td>{{ index }}</td>
                            <td>{{ item }}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="fs-6 fw-light text-decoration-underline mb-3">{{ $t('models.customer.information') }}</div>
                <div v-html="state.models.customerInfoToShow" class="ql-snow ql-editor"></div>
            </template>
        </MyModal>

    </div>

</template>


<script>

// import Vue.js framework functions
//import { ref } from 'vue';
import { reactive } from 'vue'
//import { computed } from 'vue'
//import { watch } from 'vue'
import { onMounted } from 'vue'

// import external functionalities
import { Modal } from 'bootstrap';

// import application's components
import MyModal from '@/components/MyModal.vue'

// import internationalization package
import { useI18n } from 'vue-i18n'

// import Datepicker
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css'

// import composables files
import useDateTimeUtilsComp from '@/composables/useDateTimeUtilsComp.js'
import useObjectsUtils from '@/composables/useObjectsUtils.js'
import useMealsComp from '@/composables/useMealsComp.js'
import useCustomersComp from '@/composables/useCustomersComp.js'



export default {

    name: 'deliveries',

    components: {
        Datepicker,
        MyModal,
    },

    setup() {

        const { t, locale } = useI18n({ useScope: 'global' })
        const useDateTimeUtils = useDateTimeUtilsComp()
        const objUtils = useObjectsUtils()

        const useMeals = useMealsComp()
        const useCustomers = useCustomersComp()


        const state = reactive({
            date: new Date(),

            modal: {
                //edit: 'editMealModal',
                show: 'showMealModal',
                //changeGroupAlert: 'changeMealGroupAlertModal',
                //alert: 'alertModal',
                header: '',
                action: '',
                //componentEdit: null,
                componentShow: null,
                //componentGroupChangeAlert: null,
                //componentAlert: null,
            },

            models: {
                meal: null,
                mealToShow: {},
                customerInfoToShow: '',
                selectedCustomer: '',
            },
        })


        /**
         * Function called when component is mounted
         * @param  {[type]} state               [description]
         * @return {[type]}       [description]
         */
        onMounted(() => {
            getMeals()
            state.modal.componentShow = new Modal(document.getElementById(state.modal.show))
        })

        /**
         * Gets all Meals resources from backend, not depending on other resource,
         * within the time interval from-to.
         * @return {Array}  Array of Object Meal resources
         */
        function getMeals() {
            useMeals.getMealsFromDateInterval({
                start: useDateTimeUtils.formatIsoDate(new Date(state.date)),
                end: useDateTimeUtils.formatIsoDate(new Date(state.date))
            })
        }

        /**
         * Set modal attributes and open modal form to show a resource
         * @return {void} [void]
         */
        function showResource(resource) {

            // Define modal attributes
            state.modal.header = t('form.cards.meals.headers.show')
            state.modal.action = 'Show'

            // Open Modal component
            state.modal.componentShow.toggle()

            // Erase error messages
            useMeals.useErrors.useResetErrors()

            // Initialize the resource to show
            state.models.mealToShow = {}

            //let customer = {}
            useCustomers.showResource(resource.customer_id)
            .then((res) =>{
                // Set data to display in modal window
                state.models.mealToShow = objUtils.cloneObject( concatMealAndCustomerAttribute(resource, res) )
            })

            // Clone user's attributes
            state.models.meal = objUtils.cloneObject(resource)
            state.models.customerInfoToShow = resource.info

        }

        // Create concatenated object to display
        /**
         * Concat Meal resource with corresponding Customer resource to
         * iterate on each to display in Modal form. This function helps to
         * simplify the iteration on the Object in the Modal form.
         * @param  {Object} meal    Meal Object to concat
         * @param  {Object} customer    Customer Object to concat
         * @return {Object} obj Object with concatenated Customer & Meal
         */
        function concatMealAndCustomerAttribute(meal, customer) {
            let obj = {}

            obj[t('models.meal.date')] = useDateTimeUtils.showDate(new Date(meal.date))

            obj[t('models.customer.civility')] = customer.civility
            obj[t('models.customer.customer')] = customer.lastname.toUpperCase() + ' ' + customer.firstname
            obj[t('models.customer.address')] = customer.street + ' ' + customer.number
            obj[t('models.customer.locality')] = customer.postcode + ' ' + customer.city

            obj[t('models.meal.portions')] = meal.portion_qty
            obj[t('models.meal.half_portions')] = meal.half_portion_qty
            obj[t('models.meal.comment')] = meal.comment

            return obj
        }

        /**
         * Add one day to the current displayed Date and then call the getMeals()
         * function to get all Meals resources from the new Date
         * @return {void} [void]
         */
        function nextDay() {
            state.date = useDateTimeUtils.addOneDay(state.date)
            getMeals()
        }

        /**
         * Substract one day to the current displayed Date and then call the getMeals()
         * function to get all Meals resources from the new Date
         * @return {void} [void]
         */
        function previousDay() {
            state.date = useDateTimeUtils.substractOneDay(state.date)
            getMeals()
        }

        /**
         * Set today to the current displaed Date and then call the getMeals()
         * function to get all Meals resources from the new Date
         * @return {void} [void]
         */
        function setToday() {
            state.date = new Date()
            getMeals()
        }

        /**
         * Return formated date to display in local form (header of table display)
         * @param  {Object} date    Date to format
         * @return {Object}     Formated date
         */
        function formatedDate(date) {
            return useDateTimeUtils.formatedDate(new Date(date), locale.value, "dddd D.M.YYYY")
        }

        // Format data
        /**
         * Format the date for the "Datepicker" input fields. This format
         * String is needed from the package Datepicker
         * @param  {Object} date    Date to format
         * @return {Sting}     Formated date
         */
        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            //return `Selected date is ${day}/${month}/${year}`;
            return `${day}/${month}/${year}`
        }

        /**
         * Function called and defines the behaviour as soon as the current
         * Date has been updated. It called the getMeals function to get
         * all Meals resources from the new Date
         * @return {void} [void]
         */
        function updateDate() {
            getMeals()
        }


        function printLabels(date) {
            //console.log("print labels from date : " + useDateTimeUtils.formatIsoDate(new Date(date)))

            let labelDate = useDateTimeUtils.formatIsoDate(new Date(date))
            useMeals.getMealsLabelsPdf(
                labelDate,
            ).then(() => {
                console.log("labels printed !")
            })
        }


        /**
         * All returned data to be available in the template "html" part
         */
        return {
            state,
            useMeals,
            useDateTimeUtils,
            nextDay,
            previousDay,
            setToday,
            formatedDate,
            format,
            updateDate,
            showResource,
            printLabels,
        }
    }
}

</script>

<style>
    @import '../../assets/css/logo.css';
    @import '../../assets/css/meals.css';
</style>
